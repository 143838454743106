.fan-primary-btn {
  padding: 6px 20px;
  min-width: 97px;
  box-shadow: rgba(40, 43, 62, 0.2) 0 5px 10px 0;
  min-height: 40px;
  font-weight: bold;
  border-radius: 40px;
  border: 0;
  font-family: "Averta Semibold", sans-serif;
  color: #ffffff;
  background-color: #FF7575;

  &:hover {
    background-color: rgb(178, 81, 81);
  }
}

.fan-secondary-btn {
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: #282B3E;
  padding: 6px 16px;
  font-size: 15px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Averta Regular, sans-serif;
  font-weight: bold;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: none;

  &:hover {
    text-decoration: underline;
  }
}
