.event-list-categories {
  .categories-list {
    display: flex;
    flex-direction: column;
    gap: _rem(10);

    .collapsable-card {
      .header {
        &.expanded {
          border-bottom: 1px solid $color-cco-border;
        }
      }
    }

    .category-title {
      font-size: _rem(17);
      @include fontAvertaSemibold;
    }
  }

  .event-category {
    padding: 0;
    border-top: 1px solid $color-cco-border;

    .header {
      width: 100%;

      .expand-icon {
        margin-left: auto !important;
      }

      .category-header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: _rem(5);
        width: 100%;
        padding: _rem(10) 0;
      }
    }

    .category-header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: _rem(5);
      width: 100%;
      padding: _rem(10) 0;

      &:hover:after {
        content: "";
        position: absolute;
        top: 0;
        left: _rem(-200);
        right: _rem(-200);
        height: 100%;
        background-color: $color-cco-green-background;
        pointer-events: none;
      }

      .MuiButton-root {
        z-index: 2;
      }
    }

    .inner {
      border-top: 1px solid $color-cco-border;
    }
  }

  .child-category {
    &:not(:first-of-type) {
      border-top: 1px solid $color-cco-border;
    }

    .child-category-header-wrapper {
      &.expanded {
        
      }
    }

    .category-header {
      display: flex;
      width: 100%;
      padding: _rem(15) 0;
      .category-title {
        &.opened {
          @include fontAvertaSemibold;
        }
      }
    }

    .inner {
      padding: _rem(15);
      background-color: rgba($color-fan-gray-title, .1);
      border-radius: _rem(8);
      margin-bottom: _rem(20);
    }
  }
}

.error {
  color: $color-cco-danger;
  font-size: 0.75rem;
}

