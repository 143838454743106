/* Dashboard */
.dashboard-wrapper {
  padding: _rem(40) _rem(35);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1340px) {
    padding: _rem(40) _rem(15);
  }

  @include media-breakpoint-down(lg) {
    position: static !important;
    height: 90vh;
  }

  @include media-breakpoint-down(md) {
    padding: 0 0 _rem(34) 0;
  }

  .header-row {
    padding-bottom: _rem(14);
    width: 100%;

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: _rem(51);
      z-index: 1;
      padding-top: _rem(20);
      h3 {
        font-size: _rem(20);
        @include fontAvertaSemibold();
      }
    }

    .dashboard-btn {
      min-width: _rem(141);
    }
  }

  &.job-list {
    .header-row {
      padding-top: _rem(8);
      padding-bottom: _rem(40);
    }

    @include media-breakpoint-down(md) {
      padding-top: 0;

      .header-row {
        padding-top: 0;
      }
    }
  }

  .columns-content-block {
    margin-left: _rem(-10);
    margin-right: _rem(-10);

    table {
      td {
        padding-top: _rem(5);
        padding-bottom: _rem(5);
        vertical-align: middle;
      }

      @include media-breakpoint-up(lg) {
        td, th {
          padding: _rem(7) _rem(18);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.user-list,
    &.transaction-list,
    &.user-list,
    &.events-list,
    &.order-list {
      padding: 0;
      margin: 0;

      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }

      .dashboard-content {
        padding: 0 _rem(50) _rem(35) _rem(50);
        min-height: 82vh;
      }
    }
  }

  &.settings-list {

    .title {
      @include fontAvertaBold();

      @include media-breakpoint-down(lg) {
        @include fontAvertaSemibold();
        margin-bottom: _rem(24);
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 0 0 _rem(90) 0;
    }

    .card {
      .card-body {
        img {
          width: _rem(50);
          height: _rem(50);
        }

        padding-top: _rem(40);

        @include media-breakpoint-down(lg) {
          padding: _rem(16);
          img {
            width: _rem(40);
            height: _rem(40);
            margin-right: _rem(16);
          }

          h4 {
            font-size: _rem(14);
          }
        }
      }
    }
  }

  &.fees {
    padding: _rem(20);
    @include media-breakpoint-up(lg) {
      padding: _rem(40) _rem(50);
    }
  }

  &.checkout-list {
    .header-row {
      padding-bottom: _rem(40);
    }

    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;

      .header-row {
        padding-bottom: _rem(30);
      }
    }

    .checkout-list {
      margin-top: _rem(14);
      @include media-breakpoint-up(lg) {
        margin-top: _rem(23);
      }
    }
  }
}
