//====================== COLORS =========================
$color-cco-white: #ffffff;
$color-cco-background: #fafafa;
$color-cco-green: #21B59B;
$color-cco-green-background: rgba(33,181,155,0.1);
$color-cco-dark: #282B3E;
$color-cco-placeholder: #B8B8B8;
$color-cco-border: #EDEDED;
$color-cco-border-light: #F8F8F8;
$color-cco-gray-light: #FAFAFA;
$color-cco-gray-background: #F0F0F0;
$color-cco-gray-background2: #D8D8D8;
$color-cco-muted: #989898;
$color-cco-danger: #FF6571;
$color-sw-blue: #3385CC;
$color-sw-blue-background: rgba(51,133,204,0.1);
$color-fan-primary: #FF7575;
$color-fan-red: #C92A45;
$color-fan-header: #E9E9E9;
$color-fan-header-active: #E4E4E4;
$color-fan-header-active-border: #D7D7D7;
$color-fan-cell-main: #F4F4F4;
$color-fan-gray-title: #9AA5B1;
$color-fan-error: #E00058;
$color-fan-orange: #FF8145;
$color-fan-rose: #FF7575;
$color-fan-bg-violet: rgba(102,0,204,0.05);
$color-fan-green-light: rgba(33,181,155,0.05);
$color-fan-primary-light: rgba(255,117,117,0.1);
$color-fan-adjustment-bg: rgba(51,133,204,0.1);
$color-fan-adjustment-bg-dark: #d7e6f3;
$color-fan-adjustment: #5A778F;
$color-fan-warning: #F1A417;
$color-fan-paper: #fafafa;
$color-fan-border: #ededed;
$color-fan-dark: #282b3e;
$color-fan-white: #ffffff;
$gray-light-bg : #F6F6F5;
$text-dark: #282B3E;
$color-dark : #2C2D2E;
$date-picker-border: #CFD1CC;
$date-picker-selected: #4C91AB;


//======================= VALUES =========================
$border-radius-lg: 22px;
$border-radius: 8px;
$border-radius-sm: 4px;

$grid-gutter-width: 1.875rem;

$spacer: 1.25rem;

//GUTTERS
$gutters: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	fan-10: $spacer * 0.5,
	fan-20: $spacer,
	fan-25: $spacer * 1.25
);

//======================= SHADOWS =========================
$shadows: (
  1: "0 0 20px 0 rgba(0,0,0,0.05)",
  2: "0 3px 6px -4px rgba(0,0,0,0.04)",
	3: "0 0 50px 10px rgba(0,0,0,0.03)",
	4: "0 0 10px 0 rgba(0,0,0,0.03)"
);

// Add app primary theme to bootstrap theme colors
// => we can now use 'app-primary' along bootstrap theme colors
// for example : .btn.btn-app-primary
$custom-colors: (
  	app-primary: $color-cco-green,
  	dark: $color-cco-dark,
  	hightlight: $color-cco-border,
  	blue: $color-sw-blue,
  	placeholder: $color-cco-placeholder,
	muted: $color-cco-muted,
	fan-primary: $color-fan-primary,
	fan-gray-light: $color-cco-background,
	fan-primary-light: $color-fan-primary-light,
	fan-rose:		$color-fan-rose,
	fp-primary: #C84352,
    fp-blue: #4C91AB,
    text-secondary: #808285,
    text-primary: #212223,
    fp-error: #ED6664,
	gray-title: $color-fan-gray-title,
	sw-blue: $color-sw-blue,
);
