/* Forms styles */
.form-group {
  margin-bottom: _rem(16);

  &.radio-group {
    padding-top: _rem(14);
  }

  &.date-input, &.select, &.static {
    margin-bottom: _rem(25);
  }

  .small-label {
    display: block;
    font-size: _rem(12);
    color: $color-cco-placeholder;
    padding-bottom: _rem(10);
  }

  .row-label {
    margin-left: _rem(5);
  }

  textarea.form-control {
    padding: _rem(10);
    resize: none;
    border-radius: _rem(4);
    border-color: $color-cco-border;
    font-size: _rem(14);
    line-height: _rem(18);

    @include hover-focus-active {
      box-shadow: none;
      border-color: $color-cco-placeholder;
    }

    &.border-bottom-flat {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  /* MuiFormControlLabel */
  .MuiFormControlLabel-root.checkbox {
    margin-top: _rem(-5);
    margin-bottom: _rem(-5);
    padding-bottom: _rem(12);
    margin-right: 0;

    .MuiTypography-root {
      font-size: _rem(14);
      line-height: _rem(18);
      padding-left: _rem(2);
    }
  }

  .checkbox-inline-row {
    label.checkbox {
      min-width: _rem(70);
      margin-right: _rem(10);
    }
  }

  &.inline-row {
    margin-left: _rem(-5);
    margin-right: _rem(-5);

    .inline-item {
      margin: 0 _rem(5);
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;

      .inline-item {
        margin: 0;
      }

      [class*='col-'] {
        + [class*='col-'] {
          margin-top: _rem(16);
        }
      }
    }
  }

  // form group error
  .error {
    color: $color-cco-danger;
    font-size: 0.75rem;
  }

  &.row {
    &.static {
			@include media-breakpoint-down(lg) {
				margin-bottom: _rem(16);
			}
    }
		@include media-breakpoint-down(lg) {
			.col + .col {
				margin-top: _rem(16);
			}
		}
  }
}

//Underline color
.MuiTextField-root {
  .MuiInputBase-root {
    &:before {
      border-bottom-color: $color-cco-dark !important;
    }
  }
}

// Form autocomplete component
// Used to obtain a smaller size, the same as other inputs
.autocomplete-sm {
  .MuiFormControl-root {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
      padding: _rem(5);
    }
    .MuiOutlinedInput-notchedOutline {
      height: _rem(36.62);
      top: 0;
    }
  }
}

.fix-labels {
  .form-group {
    .small-label {
      line-height: _rem(15);
      padding-bottom: _rem(10);
    }
  }
}

/* Remove arrow for number field */
.no-arrow {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.no-arrow {
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.submit-error {
  color: $color-fan-primary;
}