@import "~react-virtualized/styles.css";

/* DataTable styles */
table[aria-label="Data table"],
[aria-label="Data table"] {
  @media (min-width: 1200px) {
    table-layout: fixed;
  }

  tbody {
    tr {
      td {
        span.inner {
          display: block;
          max-width: 14vw;
        }

        span.long-inner {
          display: block;
          max-width: 30vw;
        }

        .MuiSelect-root {
          padding-left: 0;
        }
      }

      &.hidden-last-cells {
        td {
          p {
            visibility: hidden;
          }
          &:first-child,
          &:nth-child(2),
          &:last-child {
            p {
              visibility: visible;
            }
          }
        }

        td:nth-child(2) {
          p,
          span {
            @include fontAvertaSemibold();
          }

          &.marked-completed {
            span,
            svg {
              display: inline-block;
            }

            @media (max-width: 1050px) {
              span {
                display: block;
              }
              svg {
                display: none;
              }
            }
          }
        }
      }

      &.no-clickable-row {
        td {
          cursor: default;
        }
      }
    }

    &.clickable-row {
      tr {
        td {
          cursor: pointer;
        }
      }
    }
  }

  tr {
    @include media-breakpoint-down(md) {
      th,
      td {
        max-width: 35vw;
        .MuiTypography-body2,
        h6,
        span {
          font-size: _rem(12);
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    thead,
    tbody {
      th,
      td {
        padding-left: _rem(6);
        padding-right: _rem(6);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    tbody {
      td {
        span.inner {
          max-width: 8vw;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    tbody {
      span.inner {
        white-space: normal !important;
        max-width: unset !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    tbody {
      tr {
        &:nth-child(odd) {
          @include boxShadow(2);
          border-radius: 8px 0 0 8px;

          td {
            &:first-child {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
            }

            &.with-expanded-next-row {
              &:first-child {
                border-radius: 8px 0 0 0;
              }

              &:last-child {
                border-radius: 0 8px 0 0;
              }
            }
          }
        }

        &:nth-child(even) {
          td {
            padding-bottom: 8px;
          }
        }

        &.border-left {
          td:nth-child(1) {
            border-left-style: solid;
            border-left-width: _rem(5);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &.collapsable {
            td:nth-child(1) {
              position: relative;

              > div {
                border-bottom-left-radius: 0;
              }

              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: _rem(-5);
                width: _rem(5);
                height: _rem(8);
                background-color: $color-cco-background;
              }
            }
          }
        }
      }
    }
  }

  .sub-table {
    .subheader {
      border-radius: _rem(8) _rem(8) 0 0;
      padding: _rem(15) _rem(30);
      background-color: #eaedef;
    }

    .content {
      background-color: #f4f6f7;
      border-radius: 0 0 _rem(8) _rem(8);
      padding: _rem(7.5) _rem(30);

      @include media-breakpoint-up(lg) {
        &:not(.disable-scroll-auto) {
          max-height: _rem(367);
          overflow: auto;
        }
      }

      .row {
        padding: _rem(7.5) 0;
      }
    }

    .adjustment {
      .collapsable-header {
        display: flex;
        align-items: center;
        padding-top: _rem(15);

        span {
          margin-top: _rem(2);
        }
      }
      .subheader {
        background-color: $color-fan-adjustment-bg-dark;
        color: $color-fan-adjustment;
      }

      .content {
        background-color: $color-fan-adjustment-bg;
      }
    }
  }

  tbody.bulk-actions-block {
    flex: 1 0 100%;
    padding: _rem(21) 0;

    .elements {
      flex: 1 1;

      .control {
        flex: 1 1;

        &.actions {
          @include media-breakpoint-up(lg) {
            min-width: _rem(190);
            max-width: _rem(220);
          }
        }

        &.input {
          @include media-breakpoint-up(lg) {
            max-width: _rem(150);
          }
        }

        + .control {
          margin-top: _rem(8);
          @include media-breakpoint-up(lg) {
            margin-left: _rem(10);
            margin-top: 0;
          }
        }
      }
    }

    .action-button {
      .MuiButtonBase-root {
        @include media-breakpoint-up(lg) {
          min-width: _rem(97);
        }
      }

      @include media-breakpoint-down(lg) {
        padding-top: _rem(16);

        .MuiButtonBase-root {
          min-width: _rem(100);
        }
      }
    }

    .MuiSelect-root {
      padding-left: _rem(10);
    }

    .triggered {
      padding-top: _rem(4);
    }

    .triggered-row {
      opacity: 1;
      td {
        position: relative;

        .action-button {
          .MuiButtonBase-root {
            position: absolute;
            right: _rem(23);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        &:first-child {
          td {
            padding-right: 35% !important;
          }
        }
      }
    }
  }

  &.bulk-opacity {
    tr {
      opacity: 0.4;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
    }
  }
}

.events-table {
  tbody {
    td.MuiTableCell-root {
      vertical-align: middle !important;
    }
  }
}

picture.event-picture {
  position: relative;
  .img-fluid {
    width: _rem(35);
    min-width: _rem(35);
    height: _rem(35);
    object-fit: cover;
    transform: translate3d(0px, 0px, 0.1px);
  }
  &.premium {
    position: relative;
    .event-mark {
      display: flex;
      align-items: end;
      position: absolute;
      top: 0;
      left: 0;
      width: _rem(15);
      height: _rem(15);
      z-index: 100;
    }
  }
}

.performer-table {
  tbody {
    td.MuiTableCell-root {
      vertical-align: middle !important;
    }
    tr.content-row {
      td.MuiTableCell-root {
        padding-top: _rem(6);
        padding-bottom: _rem(6);
      }
    }
  }
}

.opacity-backdrop {
  position: fixed;
  top: 0;
  left: 15rem;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);

  @include media-breakpoint-down(lg) {
    top: _rem(50); //To make burger menu not frozen
    left: 0;
  }
}

.image-cell {
  .content-wrapper {
    display: flex;
    align-items: flex-start;
    padding: _rem(12) 0 _rem(10);

    picture {
      img.img-fluid {
        width: _rem(35);
        min-width: _rem(35);
        height: _rem(35);
        object-fit: cover;
        border-radius: _rem(8);

        //hide browser default box around empty img
        &:not([src]) {
          visibility: hidden;
        }
      }
    }

    .names {
      padding-left: _rem(10);

      .product-name {
        @include fontAvertaSemibold();
        line-height: _rem(18);
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: _rem(36);
      }

      .sku-number {
        font-size: _rem(12);
        line-height: _rem(15);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @include media-breakpoint-down(md) {
      picture {
        .img-fluid {
          width: _rem(32);
          height: _rem(32);
          object-fit: cover;
        }
      }

      .names {
        .product-name {
          font-size: _rem(12);
          line-height: _rem(15);
        }

        .sku-number {
          font-size: _rem(10);
          line-height: _rem(13);
        }
      }
    }
  }
}

// virtualized table
.ReactVirtualized__Table {
  $themeDefaultBg: #fff;
  $cellPadding: 15px 15px;
  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin: 0;
    overflow: hidden;
  }
  .ReactVirtualized__Table__headerRow {
    text-transform: none;
    .MuiTableCell-head {
      display: block !important;
      padding: $cellPadding;
      width: 100%;
    }
  }

  .ReactVirtualized__Table__row {
    align-items: flex-start;

    &:not(.virtual-wrapped-row) {
      .ReactVirtualized__Table__rowColumn {
        height: var(--virtual-row-inner-height);
        .MuiTableCell-root {
          height: var(--virtual-row-inner-height);
        }
      }
    }

    .ReactVirtualized__Table__rowColumn {
      background: $themeDefaultBg;
      padding: $cellPadding;
      &.smallPadding {
        padding-left: 7px;
        padding-right: 7px;
      }
      margin: 0;
      .MuiTableCell-root {
        border-radius: 0;
        padding: $cellPadding;
        &.MuiTableCell-padding-none {
          padding-left: 0 !important;
        }
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 20px;
      }
    }

    &.virtual-wrapped-row {
      margin-top: -8px !important;
      .ReactVirtualized__Table__rowColumn {
        min-width: 100%;
        padding: 0px 15px 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.virtual-wrapped-previous-row {
      .ReactVirtualized__Table__rowColumn {
        &:first-child {
          border-bottom-left-radius: 0;
        }
        &:last-child {
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
