.fan-timepicker-input {
  @extend .form-group;

  .MuiTextField-root {
    .MuiInputBase-root {
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      input {
        cursor: pointer;
        pointer-events: none; // Prevents text selection
        user-select: none; // Prevents text selection
      }
    }
  }
}

.fan-timepicker-popup {
  z-index: 9999;

  .popover-content {
    position: relative;
    border-radius: rfs-value(8px);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    border: none;
    padding: rfs-value(15px);
    display: flex;
    flex-direction: column; 
    background-color: $color-fan-white;

    .time-picker-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: rfs-value(15px); 
      .time-picker {
        display: flex;
        align-items: center;
        margin-right: rfs-value(10px);

        .time-unit {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          .up,
          .down {
            padding: rfs-value(4px);
            background: none;
            border: none;
            cursor: pointer;
            color: $color-fan-border;
            position: absolute;

            &:hover {
              color: $color-fan-red;
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          .up {
            top: rfs-value(-20px);
          }

          .down {
            bottom: rfs-value(-20px);
          }

          .input {
            width: rfs-value(60px);
            text-align: center;
            font-size: rfs-value(25px);
            border: 1px solid $color-fan-border;
            background-color: $color-fan-paper;
            color: $color-fan-dark;
            padding: rfs-value(10px);
            border-radius: rfs-value(2px);
            @include fontAvertaSemibold;

            &:disabled {
              color: $color-cco-muted;
            }
          }
        }

        .colon {
          font-size: rfs-value(24px);
          margin: 0 rfs-value(4px);
          color: $color-fan-dark;
          @include fontAvertaSemibold;
        }
      }

      .am-pm-buttons {
        .button-group {
          display: flex;
          flex-direction: column;

          .button {
            text-transform: uppercase;
            font-size: rfs-value(12px);
            padding: rfs-value(6.5px) rfs-value(10px);
            background-color: $color-fan-paper;
            border: 1px solid $color-fan-border;
            cursor: pointer;
            color: $color-fan-gray-title;
            @include fontAvertaSemibold;

            &:first-child {
              border-radius: rfs-value(2px) rfs-value(2px) 0 0;
            }

            &:last-child {
              border-radius: 0 0 rfs-value(2px) rfs-value(2px);
            }

            &:hover {
              background-color: rgba(255, 0, 0, 0.1);
            }

            &.active {
              background-color: $color-cco-green;
              color: $color-fan-white;
              border-color: $color-cco-green;
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
