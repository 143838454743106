/* Filter block styles */
.filter-content {
	display: flex;
	flex-direction: column;
  flex: 1 0;

  /* Filter header */
  .filter-header {
    padding-left: _rem(40);
    padding-right: _rem(38);
    padding-bottom: _rem(20);

    /* Title */
    .filter-header-title {
      padding-top: _rem(48);

      svg {
        width: _rem(18) !important;
        margin-right: _rem(10);
      }

      @include media-breakpoint-down(md) {
        padding-top: _rem(30);
      }

      .filter-counter-indicator {
        color: $color-cco-green;
        font-weight: bold;
        background-color: $color-cco-dark;
        padding: _rem(10);
        min-width: _rem(40);
        min-height: _rem(40);
        border-radius: _rem(10);
      }
    }
  }

  .filter-items-container {
    overflow-y: auto;
    height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);

    @include media-breakpoint-down(md) {
      height: calc(75vh - 250px);
      max-height: calc(75vh - 250px);
    }
  }

  //collapsable block
  .card.collapsable-block {
    border-color: $color-cco-border;
    border-width: 1px;
    border-style: none none solid none;
    border-radius: 0;

    &:last-child {
      border-bottom-style: none;
    }

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: _rem(16) _rem(35) _rem(15) _rem(40);

      .card-title {
        font-size: _rem(14);
        line-height: _rem(18);
        margin-bottom: 0;
      }
    }

    .card-body {
      padding: 0 _rem(35) _rem(5) _rem(40);

      .checkbox-filter-row {
        display: flex;
        padding-bottom: _rem(5);
        margin-bottom: 0;

        .MuiCheckbox-root {
          padding: 0;
          margin-right: _rem(5);
          margin-left: _rem(11);
        }

        .MuiFormControlLabel-label {
          font-size: _rem(14);
        }
      }

      .search-filter-row {
        padding-top: _rem(1);
        padding-bottom: _rem(11);

        .form-group {
          margin-bottom: 0!important;
        }

        //Search field styles
        .MuiPaper-root {
          border-radius: _rem(4);
          padding: _rem(10);

          .MuiButtonBase-root.MuiIconButton-root {
            padding-right: 0;

            .MuiSvgIcon-root {
              font-size: _rem(12) !important;
            }
          }
        }

        .filter-tags {
          margin: 0 _rem(-8);
          padding-top: _rem(8);
        }
      }
    }

    &.expanded {
      background-color: $color-cco-background;
      border-bottom-style: solid;

      &.select {
        min-height: _rem(427);
      }

      &.checkboxes {
        .card-body {
          padding-left: _rem(35);
        }
      }

      &.date_select {
        min-height: _rem(100);
      }

      &.quantity_select {
        min-height: _rem(100);
        .inputs {
          margin-top: _rem(10);
          padding-bottom: _rem(20);
          .num-input {
            width: 100%;

            .MuiInputBase-input {
              box-shadow: none;
              border-radius: 4px;
            }
          }

          .between-wrapper {
            display: flex;
            .num-input + .num-input {
              margin-left: _rem(10);
            }
          }
        }
      }

      .date-selects-wrapper {
        .MuiFormControl-root {
          margin-bottom: _rem(10);
        }
      }

      .last-days-inputs {
        .MuiFormControl-root {
          margin-bottom: 0;
        }

        .days-input {
          width: _rem(50);
          margin-right: _rem(10);

          .MuiInputBase-input {
            box-shadow: none;
            border-radius: 4px;
          }

          // Hide spinner with number input
          input[type='number'] {
            -moz-appearance: textfield; /*For FireFox*/

            &::-webkit-inner-spin-button { /*For Webkits like Chrome and Safari*/
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    &.checkboxes {
      .card-body {
        padding-left: _rem(35);
      }
    }

    &.filter-item-search {
      .card-body {
        padding-bottom: _rem(16);
      }
    }

    &.filter-item-autocomplete {
      .card-body {
        padding-bottom: _rem(16);
        .MuiInputBase-root.MuiOutlinedInput-root {
          fieldset {
            border: none !important;
          }
        }
      }
    }
  }
}

//Bottom actions
.filter-bottom-actions {
  flex: 0 1;
  padding: _rem(10) _rem(35) _rem(50) _rem(10);

  .MuiButtonBase-root {
    width: 50%;
    justify-content: center;
    .MuiButton-label {
      font-weight: bold;
    }

    + .MuiButtonBase-root {
      margin-left: _rem(10);
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: _rem(30);
    padding-bottom: _rem(30);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: $color-cco-white;
  }

  @include media-breakpoint-down(lg) {
    &.filter-bottom-actions-lg {
      padding-top: _rem(30);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background-color: $color-cco-white;
    }
  }
}

.filter-bottom-actions-custom {
  @extend .filter-bottom-actions;
  padding: _rem(10) _rem(35) _rem(0) _rem(10);
}